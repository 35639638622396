import _upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Countdown from '../components/Countdown';
import DataTable from '../components/DataTable';
import { formatCurrency, formatDate } from '../lib/formatters';
import useTableUtils from '../lib/useTableUtils';
import useWaitCursor from '../lib/useWaitCursor';

const columns = [
  {
    accessor: row => row.product.name,
    key: 'name',
    label: 'Domain',
    Cell: row => (
      <Link component={RouterLink} to={`/sales/${row.id}`}>
        {row.product.name}
      </Link>
    ),
  },
  {
    isSortable: false,
    key: 'status',
    label: 'Status',
    Cell: row => {
      let result = _upperFirst(row.status);
      if (['active', 'ended', 'future'].includes(row.status)) {
        result = `Current`;
      }
      if (row.status === 'cancelled' && row.cancelReason) {
        result += ` – ${row.cancelReason}`;
      }
      return result;
    },
  },
  {
    key: 'price',
    label: 'Current price',
    Cell: row => formatCurrency(row.price),
  },
  {
    key: 'bidCount',
    label: 'Bids',
  },
  {
    key: 'viewCount',
    label: 'Views',
    Cell: row => Math.max(0, row.viewCount),
  },
  {
    key: 'endDate',
    label: 'Time remaining',
    sortKey: 'timeLeft',
    Cell: row => <Countdown to={row.endDate} />,
  },
  {
    key: 'startDate',
    label: 'Start date',
    Cell: row => formatDate(row.startDate),
  },
  {
    key: 'endDate',
    label: 'End date',
    sortKey: 'timeLeft',
    Cell: row => formatDate(row.endDate),
  },
];

const filterDefs = [
  {
    facets: [
      {
        label: 'Current',
        value: 'current',
      },
      {
        label: 'Cancelled',
        value: 'cancelled',
      },
      {
        label: 'Sold',
        value: 'sold',
      },
      {
        label: 'Any (slow)',
        value: '',
      },
    ],
    formatter: _upperFirst,
    label: 'Status',
    key: 'status',
    type: 'facet',
  },
  {
    facets: [
      {
        label: 'Any',
        value: '',
      },
      {
        label: 'Namecheap',
        value: 'namecheap',
      },
      {
        label: 'Namesilo',
        value: 'namesilo',
      },
      {
        label: 'XYZ Registry',
        value: 'xyzRegistry',
      },
      {
        label: 'Titan Research',
        value: 'titan',
      },
      {
        label: 'Identity Digital',
        value: 'identityDigital',
      },
    ],
    label: 'Type',
    key: 'auctionType',
    type: 'facet',
  },
  {
    label: 'Start date',
    key: 'start',
    type: 'dateRange',
  },
  {
    label: 'End date',
    key: 'end',
    type: 'dateRange',
  },
  {
    label: 'Bid count',
    key: 'bidCount',
    type: 'intRange',
  },
];

// function formatTypeFacet(type) {
//   switch (type) {
//     case 'auction':
//       return 'Auction';
//     case 'buy':
//       return 'Buy Now';
//     default:
//       return;
//   }
// }

function getDateRangeFilter(from, to) {
  if (!from && !to) return;
  return {
    min: from ? moment(from).unix() : undefined,
    max: to ? moment(to).unix() : undefined, // This makes the range inclusive
  };
}

function getIntRangeFilter(from, to) {
  if (!from && !to) return;
  return {
    min: from ? window.parseInt(from, 10) : undefined,
    max: to ? window.parseInt(to, 10) : undefined,
  };
}

export default function SaleTable() {
  const { filter, setFilter, setState, state } = useTableUtils({
    onParseFilter: params => {
      if (params.report) {
        if (params.report === 'last30') {
          return {
            end_from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            ...params,
          };
        }
        if (params.report === 'endingToday') {
          return {
            end_from: moment().format('YYYY-MM-DD'),
            end_to: moment().add(1, 'days').format('YYYY-MM-DD'),
            ...params,
          };
        }
      }
      return params;
    },
  });
  const { order, orderBy, page = 1, pageSize = 100 } = state;
  const { data, error, loading } = useQuery(
    gql`
      query SaleTablePage(
        $filter: SaleFilter
        $page: Int
        $pageSize: Int
        $sort: [SaleSort]
      ) {
        sales(filter: $filter, page: $page, pageSize: $pageSize, sort: $sort) {
          isResultCountCached
          items {
            bidCount
            cancelReason
            endDate
            id
            price
            startDate
            status
            viewCount
            product {
              name
              registeredDate
            }
          }
          page
          pageCount
          pageSize
          total
        }
      }
    `,
    {
      variables: {
        page,
        pageSize,
        filter: {
          auctionType: filter.auctionType,
          bidCount: getIntRangeFilter(filter.bidCount_from, filter.bidCount_to),
          keywords: filter.query,
          endDate: getDateRangeFilter(filter.end_from, filter.end_to),
          startDate: getDateRangeFilter(filter.start_from, filter.start_to),
          statusDb: filter.status,
        },
        sort:
          order && orderBy
            ? [
                {
                  column: orderBy,
                  direction: order,
                },
              ]
            : null,
      },
    },
  );

  useWaitCursor(loading);

  if (error) {
    return (
      <Alert className="m-4" severity="error" variant="filled">
        {error.message}
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>Auctions</title>
      </Helmet>
      <DataTable
        data={data?.sales}
        columnDefs={columns}
        filter={filter}
        filterDefs={filterDefs}
        loading={loading}
        onSetPage={page => setState({ page })}
        onSetPageSize={pageSize => setState({ page: 1, pageSize })}
        onSetFilter={setFilter}
        onSort={(order, orderBy) => setState({ order, orderBy })}
        order={order}
        orderBy={orderBy}
        page={page}
        pageSize={pageSize}
      />
    </>
  );
}
