import cx from 'classnames';
import { ExportToCsv } from 'export-to-csv';
import _upperFirst from 'lodash/upperFirst';
import { useState, useCallback, useMemo } from 'react';
import {
  Button,
  Chip,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatDate } from '../lib/formatters';
import isDateInputSupported from '../lib/isDateInputSupported';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  actionsBar: {
    backgroundColor: theme.palette.background.default,
  },
}));

const exporter = new ExportToCsv({
  filename: 'report',
  useKeysAsHeaders: true,
});

function formatDateFacets([from, to]) {
  if (from && to) {
    return `${formatDate(from)} – ${formatDate(to)}`;
  } else if (from) {
    return `After ${formatDate(from)}`;
  } else if (to) {
    return `Before ${formatDate(to)}`;
  }
}

function formatIntFacets([from, to]) {
  if (from && to) {
    return `${from} – ${to}`;
  } else if (from) {
    return `${from}+`;
  } else if (to) {
    return `Less than ${to}`;
  }
}

function getFilterFormatter(filter) {
  if (filter.formmatter) {
    return filter.formatter;
  }
  if (filter.type === 'facet') {
    return _upperFirst;
  }
  if (filter.type === 'dateRange') {
    return formatDateFacets;
  }
  if (filter.type === 'intRange') {
    return formatIntFacets;
  }
}

function Filter({ children, label, onDelete, name, value }) {
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  return (
    <div className="inline-block">
      <Chip
        aria-describedby={name}
        className="mr-2 my-1 text-sm h-8"
        color={value ? 'primary' : 'default'}
        label={value ? value : label}
        onClick={event => setAnchor(event.currentTarget)}
        onDelete={value ? onDelete : null}
        variant="outlined"
      />
      <Popper
        id={name}
        open={open}
        anchorEl={anchor}
        className="mt-3"
        placement="bottom-start">
        <Paper className="inline-block">
          <ClickAwayListener
            onClickAway={() => setAnchor(null)}
            mouseEvent="onMouseUp">
            <Paper>{children}</Paper>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
}

export default function DataTable({
  data,
  columnDefs,
  filter,
  filterDefs,
  isRowSelected = () => false,
  loading,
  onSetFilter,
  onSetPage,
  onSetPageSize,
  onSort,
  order,
  orderBy,
  page,
  pageSize,
  searchHelperText = '',
  showSearch = true,
}) {
  const [query, setQuery] = useState(filter.query || '');
  const styles = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const columns = useMemo(
    () =>
      columnDefs.map(def => ({
        accessor: row => row[def.key],
        formatter: value => value,
        isSortable: true,
        sortKey: def.key,
        Cell: row => row[def.key],
        ...def,
      })),
    [columnDefs],
  );

  const datePickerProps = useCallback(
    filterKey => ({
      className: 'mr-4 mb-4 block sm:inline-block w-auto',
      inputProps: {
        pattern: 'd{4}-d{2}-d{2}',
      },
      onChange: event => {
        onSetFilter({
          [filterKey]: /\d{4}-\d{2}-\d{2}/.test(event.target.value)
            ? event.target.value
            : null,
        });
      },
      type: 'datetime-local',
      variant: 'standard',
      defaultValue: filter[filterKey] || null,
    }),
    [filter, onSetFilter],
  );

  const handleChangePage = useCallback(
    (_event, newPage) => {
      onSetPage(newPage + 1);
    },
    [onSetPage],
  );

  const handleChangeRowsPerPage = useCallback(
    event => {
      onSetPageSize(parseInt(event.target.value, 10));
    },
    [onSetPageSize],
  );

  const handleRequestSort = useCallback(
    property => {
      onSort(
        orderBy !== property || order === 'asc' ? 'desc' : 'asc',
        property,
      );
    },
    [onSort, order, orderBy],
  );

  return (
    <>
      <div className="mt-4 mx-4 lg:mx-5">
        <div className="flex flex-wrap">
          {showSearch && (
            <form
              className="inline-flex"
              onSubmit={event => {
                event.preventDefault();
                onSetFilter({ query });
              }}>
              <TextField
                className="w-56 mb-3 mr-4"
                helperText={searchHelperText}
                id="search"
                inputProps={{ 'aria-label': 'Search' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
                onChange={event => {
                  if (!event.target.value) {
                    onSetFilter({ query: '' });
                  }
                  setQuery(event.target.value);
                }}
                size="small"
                type="search"
                value={query}
                variant="outlined"
              />
            </form>
          )}
          <div className="flex-auto">
            {filterDefs.map(f => (
              <Filter
                key={f.key}
                label={f.label}
                name={f.key}
                onDelete={() => {
                  if (f.type === 'facet') {
                    onSetFilter({ [f.key]: null });
                  } else if (f.type === 'dateRange' || f.type === 'intRange') {
                    onSetFilter({
                      [`${f.key}_from`]: null,
                      [`${f.key}_to`]: null,
                    });
                  }
                }}
                value={getFilterFormatter(f)(
                  f.type === 'dateRange' || f.type === 'intRange'
                    ? [filter[`${f.key}_from`], filter[`${f.key}_to`]]
                    : filter[f.key],
                )}>
                {f.type === 'facet' ? (
                  <FormControl className="p-5 pr-8" component="fieldset">
                    <FormLabel className="mb-3">{f.label}</FormLabel>
                    <RadioGroup
                      aria-label={f.label}
                      name={f.key}
                      value={filter[f.key] || ''}
                      onChange={event =>
                        onSetFilter({ [f.key]: event.target.value })
                      }>
                      {f.facets.map(facet => (
                        <FormControlLabel
                          control={<Radio color="primary" size="small" />}
                          key={facet.value}
                          label={facet.label}
                          value={facet.value}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                ) : f.type === 'dateRange' ? (
                  <div className="inline-block p-5">
                    <FormLabel className="block mb-6">{f.label}</FormLabel>
                    <TextField
                      {...datePickerProps(`${f.key}_from`)}
                      helperText={`From${
                        !isDateInputSupported() ? ' (YYYY-MM-DD)' : ''
                      }`}
                    />
                    <TextField
                      {...datePickerProps(`${f.key}_to`)}
                      helperText={`To${
                        !isDateInputSupported() ? ' (YYYY-MM-DD)' : ''
                      }`}
                    />
                  </div>
                ) : f.type === 'intRange' ? (
                  <div className="inline-block p-5">
                    <FormLabel className="block mb-6">{f.label}</FormLabel>
                    <div className="inline-flex gap-4">
                      <TextField
                        className="w-20"
                        placeholder="min"
                        onChange={event => {
                          onSetFilter({
                            [`${f.key}_from`]: event.target.value,
                          });
                        }}
                        size="small"
                        type="number"
                        value={filter[`${f.key}_from`] || ''}
                        variant="standard"
                      />
                      <TextField
                        className="w-20"
                        placeholder="max"
                        onChange={event => {
                          onSetFilter({ [`${f.key}_to`]: event.target.value });
                        }}
                        size="small"
                        type="number"
                        value={filter[`${f.key}_to`] || ''}
                        variant="standard"
                      />
                    </div>
                  </div>
                ) : null}
              </Filter>
            ))}
          </div>
        </div>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell
                  key={`${col.key}${index}`}
                  className={cx(col.className, 'whitespace-nowrap', {
                    'pl-6': index === 0,
                  })}>
                  {col.isSortable ? (
                    <TableSortLabel
                      active={orderBy === col.sortKey}
                      direction={orderBy === col.sortKey ? order : 'desc'}
                      onClick={() => handleRequestSort(col.sortKey)}>
                      {col.label}
                      {orderBy === col.sortKey ? (
                        <span className="hidden">
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    col.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: theme.palette.dataTableBg }}>
            {loading
              ? Array.from({ length: 25 }).map((_, i) => (
                  <TableRow key={i}>
                    {columns.map((col, index) => (
                      <TableCell
                        className={cx(col.className, 'whitespace-nowrap', {
                          'pl-6': index === 0,
                        })}
                        component="td"
                        key={`${col.key}${index}`}
                        scope="row">
                        <Skeleton variant="text" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : data.items.map(row => (
                  <TableRow key={row.id} selected={isRowSelected(row)}>
                    {columns.map((col, index) => (
                      <TableCell
                        className={cx(col.className, 'whitespace-nowrap', {
                          'pl-6': index === 0,
                        })}
                        component="td"
                        key={`${col.key}${index}`}
                        scope="row">
                        {col.Cell(row, location)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        className={cx(
          styles.actionsBar,
          'flex items-center justify-between sticky bottom-0 shadow-inner',
        )}>
        <Button
          className="mx-4"
          color="primary"
          onClick={() => {
            exporter.generateCsv(
              data.items.map(row => {
                const result = {};
                columns.forEach(col => {
                  result[col.key] = col.accessor(row);
                });
                return result;
              }),
            );
          }}
          size="small"
          startIcon={<SaveAltIcon />}>
          <span className="inline lg:hidden">Export</span>
          <span className="hidden lg:inline">Export to CSV</span>
        </Button>
        <TablePagination
          rowsPerPageOptions={[100, 5000, 10000, 20000, 50000]}
          component="div"
          count={data?.total ?? 0}
          rowsPerPage={pageSize}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
